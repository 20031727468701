import React from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import UsersStatus from './UsersStatus';
import { actionClearStorage } from '../../redux/actions';

const Header = ({
    title,
    leftTitle,
    leftLink,
    leftItem,
    leftInfo,
    user
}) => {
    return (<div className="w3-theme w3-container">
        <div className="w3-left">
            <Link to="/sprint" className="w3-left w3-margin-right">
                <h3>
                    <i className="ion-home" />
                </h3>
            </Link>
            <h3 className="w3-left" >{title}</h3>
            <div className="w3-padding w3-left">
            <UsersStatus 
            user={user}/>
            </div>
            <h3 className="w3-left" >{leftInfo}</h3>

        </div>
        <div className="w3-right">
            <div className="w3-right w3-display-container">
                <img src={`${user?.avatar_url}`} style={{height:'48px',margin:'4px'}}/>
                <button title="Logout " onClick={(e)=> {
                    if (window.confirm('Are you sure you want to logout')) {
                        actionClearStorage();
                        document.location.href = "/";
                    }
                }} className="w3-display-hover w3-button w3-hover-text-white w3-hover-red w3-display-middle w3-round " style={{padding:'10px 16px',opacity:'0.95'}}>
                <i className=" ion-log-out w3-text-white " style={{fontWeight:"bold",fontSize:"20px" }}></i>
                </button>
            </div>
            {leftItem}
            {leftLink && <a className="w3-right w3-padding" href={leftLink} target="_blank">
                <h3 style={{margin:"0px"}}>{leftTitle}</h3>
            </a>}
        </div>
    </div>);
};
const ms2p = (state) => {
    return {
        user :state.userInfo?.user
    }
}
export default connect(ms2p)(Header);